import { default as abuseReportsTKuNYcVmE7Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_937pfzYQfCJxMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index6QThuvKHjcMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/index.vue?macro=true";
import { default as moderation212JBH1JgpMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/moderation.vue?macro=true";
import { default as pendingBanssYoo87nAv3Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/pendingBans.vue?macro=true";
import { default as profilesL5xTKLBO2NMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/profiles.vue?macro=true";
import { default as expensesMh4CrAgq8eMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexLZ12bqywkhMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewjo2q1Vc5WxMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingmzJB05yK8RMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingMeFreSxRn7Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/translations/missing.vue?macro=true";
import { default as usersdbvtSsgCb0Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/users.vue?macro=true";
import { default as apiHrUIjlrehEMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/api.vue?macro=true";
import { default as _91slug_936UPSV4I7o2Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/blog/[slug].vue?macro=true";
import { default as index4UjX9syOdkMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93pe5qLeTNeWMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93TZZVK35jYSMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminFdozeF6l6wMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/census/admin.vue?macro=true";
import { default as indexBdH5ebWIsLMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/census/index.vue?macro=true";
import { default as contact4SG37R6rTBMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/contact.vue?macro=true";
import { default as design83zNyBgQbrMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/design.vue?macro=true";
import { default as english1YMJ3DzZzcMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/english.vue?macro=true";
import { default as faqs1q1g3t2N9Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/faq.vue?macro=true";
import { default as inclusivevOrFnASf5HMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/inclusive.vue?macro=true";
import { default as indexwoMeilmjskMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/index.vue?macro=true";
import { default as licenseS9cxaOiJsoMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/license.vue?macro=true";
import { default as academicjLuApWrTtfMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/links/academic.vue?macro=true";
import { default as indexz8tJlncleBMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/links/index.vue?macro=true";
import { default as mediamLcOHt8CSUMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/links/media.vue?macro=true";
import { default as translinguisticsnxTFpY7XRNMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/links/translinguistics.vue?macro=true";
import { default as zineqFB2qeTXnsMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/links/zine.vue?macro=true";
import { default as namesxOBkS4XLIXMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/names.vue?macro=true";
import { default as index1OyYLVYNcsMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/nouns/index.vue?macro=true";
import { default as templatesLWFfTyB2YJMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/nouns/templates.vue?macro=true";
import { default as peopleaq0pYYlcwpMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/people.vue?macro=true";
import { default as privacyHWYKpla7YhMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/privacy.vue?macro=true";
import { default as _91username_93HhUo2SSBXdMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93IONGZAN1cYMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/profile/card-[username].vue?macro=true";
import { default as editorEy2FrjCkmyMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/profile/editor.vue?macro=true";
import { default as anyPAm4BpA6AzMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/any.vue?macro=true";
import { default as askZxKOWQuuDdMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/ask.vue?macro=true";
import { default as avoiding5POkL75SRKMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/avoiding.vue?macro=true";
import { default as indexPUXiiFsHa7Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/index.vue?macro=true";
import { default as mirrorf4RjWk6ckXMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/mirror.vue?macro=true";
import { default as pronounKwf9l9N3AtMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesDhQoolsKOrMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/sources.vue?macro=true";
import { default as teamDg9Z7QhIMoMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/team.vue?macro=true";
import { default as terminologyWu5q4qP9c5Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/terminology.vue?macro=true";
import { default as termsAWlztpWcB5Meta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/terms.vue?macro=true";
import { default as user5TuhMx9WYVMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/user.vue?macro=true";
import { default as workshopsmbmeLAEuTZMeta } from "/home/admin/www/zaimki.pl/release/20241201125744/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsTKuNYcVmE7Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBanssYoo87nAv3Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiHrUIjlrehEMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_936UPSV4I7o2Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index4UjX9syOdkMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93pe5qLeTNeWMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93TZZVK35jYSMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminFdozeF6l6wMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexBdH5ebWIsLMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact4SG37R6rTBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english1YMJ3DzZzcMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqs1q1g3t2N9Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivevOrFnASf5HMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicjLuApWrTtfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexz8tJlncleBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediamLcOHt8CSUMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsnxTFpY7XRNMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineqFB2qeTXnsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesxOBkS4XLIXMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index1OyYLVYNcsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesLWFfTyB2YJMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleaq0pYYlcwpMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyHWYKpla7YhMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93HhUo2SSBXdMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93IONGZAN1cYMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorEy2FrjCkmyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyPAm4BpA6AzMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askZxKOWQuuDdMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding5POkL75SRKMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexPUXiiFsHa7Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorf4RjWk6ckXMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounKwf9l9N3AtMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesDhQoolsKOrMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamDg9Z7QhIMoMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyWu5q4qP9c5Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsAWlztpWcB5Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user5TuhMx9WYVMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsmbmeLAEuTZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241201125744/pages/workshops.vue")
  },
  {
    name: "nouns-neutratywy",
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue")
  },
  {
    name: "nouns-dukatywy",
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue")
  },
  {
    name: "nouns-osobatywy",
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue")
  },
  {
    name: "nouns-iksatywy",
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue")
  }
]